<template>
  <div>
    <ComercialReportGrid />
  </div>
</template>

<script>
import ComercialReportGrid from "@/views/commons/components/reports/tabs/comercial-report/components/ComercialReportGrid.vue";
export default {
  components: {
    ComercialReportGrid,
  },
  data() {
    return {};
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>
<style>
</style>
